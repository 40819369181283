.Rewardmodel {
  .tab-container {
    margin-bottom: 20px;
    > button {
      width: 50%;
      padding: 7px;
      color: rgb(102, 102, 102);
      background-color: transparent !important;
      outline: 0;
      border: 0;
      border-bottom: 1px solid rgb(102, 102, 102);

      &:hover {
        color: #5ec096;
      }
    }
  }
  .CheckoutPointDiv {
    text-align: start;
    h2 {
      font-size: 18px;
      font-weight: 700;
    }
    span {
      color: #666666;
      font-weight: 500;
    }
  }
  .CheckOutEachDiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    div {
      flex: 1;
      border-radius: 25px;
      border: 1px solid #469079;
      margin: 0 10px;
      padding: 3px 0;
      p {
        color: #666666;
        margin-bottom: 5px;
        h6 {
          color: #469079;
          margin: 0;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  .pending {
    background: rgb(218, 26, 5);
    color: #fff;
    border: 0px;

    &:active {
      background-color: #5ec096;
      color: #fff;
    }
  }
  .donate {
    background: rgb(250, 163, 36);
    color: #fff;
    border: 0px;

    &:active {
      background-color: #5ec096;
      color: #fff;
    }
  }

  .BenefitTitle {
    text-align: start;
    font-size: 18px;
    color: #000;
    margin-bottom: 0;
  }
  .btnActive {
    padding: 7px;
    color: #5ec096 !important;
    font-weight: bold;
    cursor: default;
    background-color: transparent !important;
    border-bottom: 2px solid #5ec096 !important;
  }

  .BenefitContent {
    display: flex;

    .benefit-boxes-design {
      text-align: center;
      color: white;
      margin-right: 25px;
      background: #469079 !important;
      border-bottom-right-radius: 25px;
      border-top-left-radius: 25px;
      .pointContent {
        width: 140px;
      }
      .points {
        font-size: 46px;
        margin: 0px;
        width: -webkit-fill-available;
      }
    }
    button.donate {
      background-color: #f99f26;
      border: 0;
      color: #fff;
      padding: 7px 17px;
      font-size: 16px;
    }
  }

  .claimedBtn {
    background-color: #5ec096;
    border-color: #5ec096;
    background-image: none;
    color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    height: auto;
    margin: 0;
    padding: 5px;
  }

  .infoImg {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    margin: 5px;
  }

  .vendorRow {
    box-shadow: 0px 6px 8px -2px #ccc;
    padding-bottom: 20px;
    position: relative;
    min-height: 60px;

    .vendor {
      width: 40px;
      height: 40px;
    }
  }
  .disableBtn {
    pointer-events: none;
    opacity: 0.4;
    background-color: #5ec096;
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
    border: 0;
    color: #fff;
    padding: 6px 10px;
    border-radius: 4px;
  }

  .claim {
    background-color: #5ec096;
    color: #fff;
    font-weight: 500;
    border: 0;
    &:active {
      background-color: #5ec096;
      color: #fff;
    }
  }

  // .btn-close {
  //   background-color: #469079;
  // }
}

.CarouselDiv {
  .react-multi-carousel-item {
    width: 220px !important;
  }
}
// Second modal Css
.SecondModal {
  .h4 {
    width: 100%;
    text-align: center;
    color: #666666;
  }
  .modal-body {
    .UniqueId {
      color: #469079;
      font-weight: 800;
      text-align: center;
    }
    .bold {
      font-weight: 800;
      font-size: 18px;
      color: #000;
    }
    .light {
      font-size: 16px;
      color: #666666;
    }
  }
  .modal-footer {
    button {
      background-color: #469079;
      font-size: 18px;
      padding: 8px 15px;
      border-radius: 10px;
      color: #fff;
      font-weight: 600;
      border: 0;
    }
  }
}

@media (max-width: 768px) {
  .Rewardmodel {
    .infoImg {
      height: auto;
      margin-bottom: 15px;
    }
    .tab-container > button {
      padding: 7px 0;
    }
    .CheckOutEachDiv {
      flex-direction: column;
      div {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
