.login-form {
  $BaseColor: #5ec096;
  max-width: 400px;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 800px;

  .form-group {
    // position: relative;
    margin-bottom: 20px;

    .groupInput{
        position: relative;
    

    .input-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #aaa;
    }
}

    input[type='email'],
    input[type='password'] {
      width: 100%;
      padding: 10px 40px 10px 30px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      color: #333;

      &::placeholder {
        color: #aaa;
      }
    }

    .error-message {
      margin-top: 5px;
      font-size: 14px;
      color: red;
    }
  }

  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: $BaseColor;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .forgetpass {
    color: #0e70d9;
    opacity: 0.75;
    cursor: pointer;
    font-size: 14px;
    margin: 15px 0;
  }

  .google-btn{
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;

    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-weight: 600;
    font-family: Roboto, sans-serif;
  }
  .or{
    padding: 6px 0px;
    color: #575555;
  }
  .signUp{
    text-align: center;
    cursor: pointer;
    color: #666;
    font-weight: bold;
    font-size: 14px;

    a{
        text-decoration: none;
    }
  }
}
