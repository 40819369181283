.Main_Div {
  display: flex;
  width: 75%;
  .Image_section {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #34c299;
    margin-right: 25px;
    width: 50%;
    img.img-responsive {
      width: 30%;
      object-fit: cover;
    }

    .Text {
      text-align: center;
      background-color: #34c299;
      width: 100%;
      color: white;
      h4 {
        font-size: revert;
      }
    }
  }
  .Details {
    width: 50%;
    .checkOutTime {
      font-weight: bolder;
      font-size: larger;
      color: #000;
    }
    p {
      color: #7e7e7e;
    }
  }
}
.historyDiv {
  box-shadow: rgba(0, 0, 0, 0.35) 0 2px 6px 3px;
}
.table__pagination__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-bottom: 7rem;

  .pagination__select {
    margin-left: 20px;
    padding: 9px;
    border: 1px solid #ccc;
    border-radius: 3px;

    &:focus-within {
      outline: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      border-radius: 3px;
      margin: 0 5px;

      a {
        display: block;
        padding: 8px 16px;
        text-decoration: none;
        color: #000;
        border: 1px solid #ccc;
        border-radius: 3px;

        &:hover {
          background-color: #ccc;
        }
      }

      &.active {
        a {
          background-color: #34c299;
          color: #fff;
          border-color: #34c299;

          &:hover {
            background-color: #34c299;
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Main_Div {
    width: 100%;
    display: block;
    .Image_section {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #34c299;
      margin: auto;
      width: 100%;
      img.img-responsive {
        width: 35%;
      }
    }
    .Details {
      width: 100%;
      padding-top: 15px;
      .checkOutTime {
        font-weight: 800;
        font-size: 15px;
        margin-bottom: 10px;
      }
      h5 {
        color: #333333;
        font-size: 15px;
        font-weight: 700;
      }
      p {
        color: #333333;
        font-size: 15px;
      }
    }
  }
  .table__pagination__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 3rem;
    ul {
      margin: 0;
      li {
        margin: 0;
        padding: 0;
        a {
          padding: 2px 12px;
        }
      }
    }
    .pagination__select {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      .dropdwon_Option {
        font-size: xx-small;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .Main_Div .Image_section img.img-responsive {
    width: 60%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .Main_Div .Image_section img.img-responsive {
    width: 40%;
  }
}

@media screen and (min-width: 820px) and (max-width: 1024px) {
  .Main_Div {
    width: 100%;
    display: flex;
    .Image_section {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #34c299;
      margin: auto;
      width: 50%;
    }
    .Details {
      width: 50%;
      padding-left: 25px;
    }
  }
}
