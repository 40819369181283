.registration-form {
  $BaseColor: #5ec096;
  max-width: 400px;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;

  .form-group {
    margin-bottom: 20px;
  }

  .regForm {
    overflow-y: auto;
    height: 343px;

    .regEmailIcon {
      margin-top: 0.5%;
    }

    .select-domain {
      color: #888;
      font-size: 14px;

      strong {
        color: black;
      }
    }

    .regMobile {
      width: 24px;
      height: 41px;
      margin-right: 2px;
      margin-left: -17px;
      object-fit: contain;

    }

    .countryCode {
      margin-left: 25px;
    }

    input:focus {
      box-shadow: none;
    }

    select:focus {
      box-shadow: none;
      border-color: #ccc;
    }
  }

  .extraIcon {
    position: relative;

    label {
      color: #888;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .extraFieldIcons {
      position: absolute;
      left: 10px;
      font-size: 19px;
      color: #b4b0b0;
      top: 45px;
    }

  }

  .mobileInput {
    display: flex;

    p {
      margin: 0;
    }
  }

  .devMobileInput {
    padding-left: 60px;
  }


  .groupInput {
    position: relative;

    .input-icon {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #ccc;
    }
  }



  .emailFrom {
    margin: 0;
    position: relative;
    margin-bottom: 20px;

    .error-emails {
      margin-bottom: 12px;
      // margin-bottom: 5px;
      // top: 45px;
      // width: 100%;
    }
  }

  .domainAppend {
    display: flex;
    margin-bottom: 0;

    .domainReg {
      height: 47px;
      margin: 0 0 0 10px;
      width: 104px;
    }

    .regEmail {
      width: 186px;
    }

    .domainReg {
      width: 146px;
    }
  }

  .regEmailIcon {
    margin-top: -10px;
  }

  .select-domain {
    right: 126px;
    font-size: 12px;

    strong {
      font-size: 14px;
    }
  }

  .error-emails {
    display: flex;
    justify-content: space-between;
  }

  input {
    padding: 10px 0px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;

    &:focus {
      outline: none;
      border-color: #888;
    }
  }

  .error-message {
    color: red;
    font-size: 14px;
  }

  .activeBtn {
    background-color: $BaseColor;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;

    &:hover {
      background-color: $BaseColor;
    }
  }

  .disableBtn {
    background-color: $BaseColor;
    color: #fff;
    opacity: 0.75;
    cursor: not-allowed;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    width: 100%;
    margin-top: 10px;

    &:hover {
      background-color: $BaseColor;
    }
  }

  .signIn {
    margin-top: 10px;
    cursor: pointer;

    a {
      text-decoration: none;
      font-weight: 700;
      opacity: 0.75;
    }
  }

  // Media Query for Small Screens (e.g., mobile)

  .checkbox-group-terms {
    display: flex;
    margin: 0;

    label {
      margin: 0 12px;
    }

    .error-message {
      color: red;
      margin-top: 2px;
      font-size: 14px;
    }
  }

  .infoText {
    margin-top: 0;
    font-size: 14px;
    text-align: "center";
    color: "#666";
  }

  .linkText {
    color: $BaseColor;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .form-group {
    margin-bottom: 10px;
  }

  .groupInput .input-icon {
    left: 8px;
  }

  input {
    padding: 8px 30px 8px 20px;
    font-size: 14px;
    width: 100% !important;
  }

  .activeBtn,
  .disableBtn {
    padding: 8px 16px;
    font-size: 14px;
  }

  .registration-form {
    .domainAppend {
      .domainReg {
        height: auto;
        padding: 9px;
      }
    }

    // .extraIcon {
    //   .extraFieldIcons {
    //     top: 45px;
    //   }
    // }
  }

}

@media (min-width:1200px) and (max-width:1400px) {
  .registration-form .domainAppend .regEmail {
    width: 100%;
  }
}