.model {
  display: flex;
  justify-content: center;

  .model-title {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0 0 9px 1px #ccc;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: -30px;
    background: #fff;

    img {
      max-width: 100px;
      max-height: 100px;
      padding: 15px;
    }
  }

  .modal-header {
    box-shadow: 0px 6px 8px -2px #ccc;
    padding-bottom: 10px;
    position: relative;
    min-height: 60px;
  }

  .modal-body {
    text-align: center;
  }

  .listName {
    font-weight: bold;
    color: #000;
    margin-bottom: 6px;
    text-transform: capitalize;
    font-size: 20px;
  }

  .modal-footer {
    border-top: none;
  }

  .listDetails {
    color: #6f7372;
  }

  .addresss {
    border-bottom: 1px solid #8a8a8a;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #6f7372;
  }

  .label.dv-star-rating-star,
  .dv-star-rating-full-star {
    background: #5ec096;
    color: #fff !important;
    font-size: 24px;
    padding: 1px 5px;
    border-radius: 4px;
    margin: 1px;
  }

  .label.dv-star-rating-star,
  .dv-star-rating-empty-star {
    color: #fff !important;
    font-size: 24px;
    background: #ababab;
    padding: 1px 5px;
    border-radius: 4px;
    margin: 1px;
  }

  .btn-close {
    font-size: 14px;
    width: 60px;
    height: 20px;
    text-transform: uppercase;
    background: #5ec096;
    padding: 10px;
    margin: 5px;
    color: #fff;
    border-radius: 3px;
    opacity: 1;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      opacity: 0.5;
    }
  }

  .btn-link {
    text-decoration: none !important;
    color: white !important;

    & > * {
      color: #fff;
      background-color: #5bc0de;
      border-color: #46b8da;
      outline: none;
      border: none;
      padding: 8px;
      border-radius: 0.2rem;
    }
  }
}
