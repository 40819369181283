.navbar {
  background-color: #5ec096 !important;
  width: 100%;
  padding: 0 !important;

  button.navbar-toggler {
    border: 0;
  }
  button.navbar-toggler:focus {
    box-shadow: none;
  }

  .logo {
    background-color: #fff;
    width: 190px;
    height: 60px;
    padding: 9px 6px;
    object-fit: contain;
  }
  .navbarLink {
    padding-top: 0px;
  }

  .navButton {
    background-color: transparent;
    border: 0;
    &:focus {
      color: white;
    }
  }

  .nav-link {
    padding: 20px 14px !important;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    &:hover {
      background-color: rgb(48, 86, 87);
      color: #fff;
    }
    .feeds:focus {
      color: white;
    }
    p.feedsColor {
      color: white !important;
      margin: 0 0;
    }
  }

  .active {
    background-color: rgb(48, 86, 87);
    color: #fff !important;
  }
}
.centerDiv {
  text-align: center;
}
.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  text-align: center !important;
}

.modal-header {
  padding: 12px 15px;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 15px 0px 15px;
  font-weight: 800;
  font-size: 24px;
  color: #000;
  background: linear-gradient(to bottom, #e9fbe9 0%, #e9fbe9 100%);
}
.modelMessage {
  color: #666666;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center !important;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .navbar {
    .navDiv {
      a.navbar-brand {
        width: 75%;
        .logo {
          width: 50%;
          height: auto;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .navbar {
    .nav-link {
      margin: 0 3px;
    }
    .logo {
      height: 35px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .navbar .logo {
    height: 40px;
  }
}
