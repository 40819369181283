.termsPolicy {

  .modal-header {
    font-size: 14px !important;
  }

  .modal-dialog {
    max-width: 600px;
  }

  .modal-title {
    font-size: 24px;

  }

  .modal-footer {
    justify-content: flex-end;
  }

}



.modalBody {
  font-size: 16px !important;
  max-height: 400px !important;
  overflow: scroll !important;
}

.preContent {
  white-space: normal !important;
}





.termsPolicyFooter {
  .close {
    font-size: 12px !important;
    color: #fff !important;
    background-color: #000 !important;

    &:hover {
      font-size: 12px !important;
      color: #fff !important;
      background-color: #000 !important;
    }
  }
}

.termsPolicyHeader {

  .btn-close {
    font-size: 14px !important;
  }

}