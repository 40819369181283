.jumbotron {
  min-height: 75vh;
  display: flex;
  align-items: center;
  .loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #5ec096;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
}

button.btn:focus:not(:focus-visible),
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

@keyframes animloader {
  0% {
    box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
  }

  33% {
    box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
  }

  66% {
    box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
  }

  100% {
    box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
  }
}

@media (max-width:768px){
  .jumbotron {
    min-height: 66vh;
  }
}
@media (min-width:768px) and (max-width:992px){
  .jumbotron {
    min-height: 76vh;
  }
}