.pVarification {
  height: 100%;

  .verifyImg {
    background-image: url('../../assests/images/hero-vendor.jpeg');
    height: 300px;
    min-width: 100%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
  }

  .card-entry-form {
    width: 400px;
    margin: -200px auto;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .headingCard {
        font-size: 11px;
      }

      .header {
        text-align: center;
      }

      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .error-message {
        color: red;
        font-size: 14px;
        margin-top: 5px;
      }
    }

    button {
      width: 100%;
      padding: 10px;
      background-color: rgb(94, 192, 150);
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgb(94, 192, 150);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}
