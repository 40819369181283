.qrCode {
  video {
    width: 100%;
  }
}

.modal-header {
  padding: 12px 15px;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 15px 0px 15px;
  font-weight: 800;
  font-size: 24px;
  color: #000;
  background: linear-gradient(to bottom, #e9fbe9 0%, #e9fbe9 100%);
}
.modelMessage {
  color: #666666;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}
