.footer {
  background-color: #305657;
  width: 100%;
  overflow-x: hidden;
  padding: 5px;
  margin-top: 100px;
  position: fixed;
  bottom: 0;
  .terms {
    font-weight: 600;
    font-size: 1.1rem;
    color: white;
  }
  .copyIcon {
    & > * {
      height: 13px;
      margin-top: -4px;
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;

    a {
      margin: 5px;
      width: 38px;
      background-color: #fff;
      color: #000;
      font-size: 18px;
      padding: 5px;
      text-align: center;
      &:hover {
        background-color: #069f33;
      }
    }
  }
  .footerSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .footer {
    .terms {
      font-size: 1rem;
    }
    .footerSec {
      flex-direction: column;
      .footerLink {
        text-align: center;
      }
      .footerIcons {
        margin-top: 5px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .pVarification {
    min-height: 120vh;
  }
}
