.Dashboard {
  margin-bottom: 5rem;

  .btn-container {
    button {
      border-radius: 0;
      background-color: #ededee;
      margin: 25px 1px;
      padding: 10px;
      font-weight: bold;
      font-size: 14px;

      .state-changer {
        color: #5ec096 !important;
        font-size: 21px;
        font-weight: 400;
      }
    }

    .state {
      border: 1.5px solid #5ec096 !important;
    }
  }

  .btnActive {
    background: #5ec096 !important;
    color: #fff;
  }

  .listIcon {
    font-size: x-large;
  }

  .listIconButton {
    background-color: #fff !important;
    color: #5ec096;
  }

  .search {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 4px 12px;
  }

  input:focus {
    border: 1px solid #ccc;
    outline: 0;
  }

  .search-box {
    // width: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .mainDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc !important;
      border-radius: 15px;
      padding: 0px 12px;
      border: none;

      .search {
        width: 250px;
        border: 1px solid #ccc;
        padding: 4px 12px;
        border: none;
      }
    }

    display: flex;

    .icons {
      color: #5ec096;
      font-size: 24px;
      margin-left: 10px;
    }

    .search-icon {
      top: 4px;
      right: 50px;
      font-size: 23px;
      border-left: 1px solid #a7a7a7;
      padding-left: 8px;
      color: #aaa;
    }
  }

  .map-container {
    margin-bottom: 10%;

    .map {
      width: 100% !important;
      height: 600px !important;
    }
  }

  .list-container {
    background-color: #f7f7f7;

    .listItem {
      align-items: center;
    }

    .listImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .first-col>h1 {
      color: #1a1a1a;
      font-family: 'Raleway', sans-serif;
      font-size: 20px;
      text-transform: capitalize;
      margin-bottom: 0px;
    }

    .first-col>p {
      color: #999999;
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 600;
    }

    .listItem {
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .Dashboard {
    margin-bottom: 6rem;

    .search-box {
      display: flex;
      align-items: center;

      .search {
        width: 100%;
      }

      .search-icon {
        right: 50px;
      }
    }

    .list-container .listItem {
      margin-bottom: 60px;
      text-align: center;

      .p-5 {
        margin-bottom: 30px;
        padding: 0 !important;
      }
    }
  }
}

@media (min-width: 910px) and (max-width: 914px) {
  .Dashboard {
    margin-bottom: 28rem;
  }
}