.MainSection {
  padding: 40px 50px;
  min-height: 80vh;
  .accountSec {
    padding: 0 100px;
  }
  .Accounts_Section {
    background: #eb9700;
    padding: 25px;
    margin-top: 50px;
    .Image_Box {
      text-align: center;
      position: relative;
      h2 {
        font-size: 24px;
        position: absolute;
        color: #fff;
        font-weight: bold;
        top: 12px;
        width: 100%;
      }
    }
    .box_text {
      text-align: center;
      color: #fff;
      font-size: 22px;
      margin-top: 40px;
    }
    .View_Container_History {
      background-color: #fff;
      text-align: center;
      padding: 30px 40px;
      cursor: pointer;
      h3 {
        color: #315657;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}

.History_box {
  background-color: #5ec096;
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;
  .History_head {
    color: #fff;
    padding: 20px;
    font-size: 22px;
    font-weight: bold;
  }
  .iconss {
    padding-right: 20px;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    color: white;
    .logout {
      font-size: 16px;
      font-weight: 600;
    }
    i.fas.fa-sign-out {
      font-size: 30px;
    }
  }
}
.Details_User {
  box-shadow: 0 7px 6px 0px #6c757d;
  margin: 0px 25px 28px;
  .userDetails {
    margin-left: 20px;
    margin-right: 25px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #6c757d;
    color: #666666;
    &:last-child {
      border-bottom: 0;
    }
    .userName {
      display: flex;
      padding: 14px;
      h3.Safe {
        padding-left: 12px;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .Values {
      padding-top: 14px;
    }
    .common {
      color: #5ec096;
      font-size: 30px;
    }
    .terms_Condition {
      text-decoration: none;
      display: flex;
      padding: 14px;
      .ReDireactLink {
        cursor: pointer;
        text-decoration: none;
        color: #666666;
        h3.Safe {
          // padding-left: px;
          padding-left: 15px;
          &:hover {
            color: #265075;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Accounts_Section {
    margin-top: 20px;
    .box_text {
      text-align: center;
      color: #fff;
      font-size: 20px;
      margin-top: 20px;
    }
    .View_Container_History {
      padding: 14px 0px;
    }
  }
  .History_box {
    background-color: #5ec096;
    font-size: small;
    margin: 0px 0px;
  }
  .Details_User {
    box-shadow: 0 7px 6px 0px #6c757d;
    margin: 11px 0px 3px;
    .userDetails {
      margin-left: 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #6c757d;
      color: #666666;
      .terms_Condition .ReDireactLink h3.Safe {
        padding-left: 12px;
        font-size: 14px;
      }
      .common {
        font-size: 17px;
      }

      .userName {
        display: flex;
        padding: 11px 3px;
        h3.Safe {
          font-size: 14px;
        }
      }
      .Values {
        padding-left: 5px;
        padding-top: 0;
        h5 {
          font-size: 14px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 912px) {
  .MainSection {
    padding: 0px;
  }
}

@media (max-width: 992px) {
  .MainSection {
    padding: 40px 10px;
    .accountSec {
      padding: 0;
      .Accounts_Section .box_text {
        margin-top: 20px;
      }
    }
  }
}
