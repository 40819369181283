.Steps {
  margin-bottom: 20px;
  .phoneIcon {
    min-height: 312px;
    object-fit: fill;
    width: 100%;
  }
  .stepSign {
    margin-top: 20px;
    margin-bottom: 30px;
    h2 {
      font-size: 38px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 10px;
    }
    p {
      font-size: 20px;
      color: #000;
    }
  }

  .text {
    font-size: 16px;
    color: #252525;
    margin: 10px;
    line-height: 18px;
  }
  .stepCount {
    font-size: 20px;
    color: #5ec096;
    font-weight: bold;
    margin: 10px;
  }
}
@media (min-width: 767px) and (max-width:992px) {
  .Steps {
    .phoneIcon{
      min-height: 240px;
    }
}
}
