.CustomMap {
  position: relative;
  .Main-div {
    position: absolute;
    // z-index: 1;
    right: 4px;
    bottom: 5px;
    .zoomIn-btn {
      background-color: #ffffff;
      padding: 0px 9px 4px;
      font-size: 22px;
      border: 0 solid grey;
    }
    .zoomOut-btn {
      background-color: #ffffff;
      padding: 0px 9px 4px;
      font-size: 22px;
      border: 0 solid grey;
    }
  }
  .marker-wrapper img {
    width: 30px !important;
    height: 30px !important;
  }
}

.custom-marker img {
  width: 40px; // Adjust the size as needed
  height: 40px; // Adjust the size as needed
}
