.forgetPass {
    .header {
        border-bottom: 1px solid #e5e5e5;
        padding: 5px 15px 0px 15px;
        text-align: left;
        // font-weight: 600;
        // font-size: 24px;
        // font-family: 'Raleway', sans-serif;
        color: #000;
        background: linear-gradient(to bottom, #e9fbe9 0%, #e9fbe9 100%);
        // background-color: red;
    }
}

.emailTxt {
    position: relative;
    // margin: 50px;
        padding: 17px 21px 12px;
    .AddressField{
        padding-top: 70px !important;
    }

    .inputText {
        width: 84%;
        padding: 5px 25px;
        font-size: 15px;
        margin: 0px;
        color: #000;
        font-weight: normal;
    }

    .icon {
        position: absolute;
        top: 57px;
        left: 9%;
        font-size: 20px;
        color: rgb(76, 74, 74)
    }

    span {
        margin-bottom: 0.5rem;
    }

    .error-message {
        margin: 0;
        padding: 0;
        color: red;
    }

    button {
        background: #5ec096;
        width: 84% !important;
        margin: 5px;
        font-size: 18px;
        font-weight: bold;
        padding: 10px;
        border-radius: 3px;
        border: 0;
        color: white;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}