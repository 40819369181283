.Nofication_message {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #66687f;
  cursor: pointer;
}
.notificationDiv {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media (max-width: 768px) {
  .Nofication_message {
    font-size: 16px !important;
  }
  .notificationDiv {
    margin-bottom: 15px !important;
  }
}
// 66687fb5
