.slider {
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: gray;
  white-space: nowrap;

  &__container {
    display: flex;
  }

  &__item {
    flex: 0 0 auto;
    width: 300px;
    padding: 20px;
    margin-right: 10px;
    background-color: #f0f0f0;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    img {
      width: 100%;
      height: auto;
    }

    h3 {
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin-top: 5px;
      font-size: 14px;
      color: #555555;
    }
  }
}
.box_listing_rewards {
  position: relative;
  text-align: center;
  clear: both;
  display: inline-block !important;
  width: 200px !important;
  max-width: 200px !important;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px #8c8c8c;
  margin: 15px;
  .background-light-green-top {
    height: 50px;
    text-align: center;
    background: #a6e6c9;
    cursor: pointer;
  }
  .background-light-green-bottom {
    min-height: 50px;
    text-align: center;
    background: #e8fff5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  img.img-rewards {
    width: 30%;
    left: 35%;
    margin: auto;
    height: 60px;
    margin-top: 20px;
    position: absolute;
    border-radius: 50%;
    background: white;
    padding: 1px;
    object-fit: cover;
  }
  p {
    padding: 45px 0px 25px 0px;
    padding-top: 34px;
    color: black;
    font-weight: bold !important;
    vertical-align: bottom;
    background: #ffffff;
  }
  h5 {
    font-size: 16px;
    background: #5ec096;
    color: white;
    padding: 5px;
    display: inline-block;
    width: 50%;
    right: 25%;
    bottom: 25%;
    font-weight: bold !important;
    position: absolute;
    border-radius: 15px;
  }
  h6 {
    bottom: 15px;
    text-align: center;
    width: 100%;
    color: black;
    font-weight: bold;
    min-height: 45px;
  }
}

.rewardsListing {
  .listing {
    position: relative;
    text-align: center;
    clear: both;
    display: inline-block !important;
    width: 100%;
    max-width: 100%;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 15px 0;
    cursor: pointer;
    .imageSize {
      max-width: 100%;
      height: 180px;
      text-align: center;
      padding: 10px;
      position: relative;
      // h2 {
      //   margin: 0;
      // }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .claimed {
        position: absolute;
        max-width: 100%;
        width: 90%;
        opacity: 0.9;
        bottom: 20px;
        display: block;
        background: #000;
        background: rgba(70, 144, 121);
        color: #fff;
        padding: 15px 5px;
        font-size: x-large;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
      }

      .reawrd-benefit-column {
        h1 {
          font-size: 17px;
          font-size: larger;
          color: black;
          font-weight: bold;
          padding: 35px 0px 20px 0px;
        }
      }
    }
  }
}
