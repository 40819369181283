.MainBody {
  width: 100%;
  margin: auto;
  .body {
    color: white !important;
    font-size: small;
    .Nodata {
      // display: flex;
      // align-items: center;
      // min-height: 100vh;
      // background-color: rgb(224, 224, 224);
    display: flex;
    align-items: center;
    min-height: 80vh;
    font-style: italic;
    color: rgb(189 189 189);

      .text-center {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
      }
    }
  }
}

.NoDataBg {
  height: 50vh;
  text-align: center;
  position: relative;

  img.NoDataImg {
    width: 50%;
    opacity: 0.4;
  }

  .NoContent {
    height: 100%;
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;

    h2 {
      font-size: 32px;
      font-weight: 700;
      font-family: 'Roboto Condensed';
    }
  }
}

@media (min-width: 768px) {
  .NoDataBg {
    .NoContent {
      h2 {
        font-size: 70px;
        font-weight: bold;
      }
    }
  }
}
@media (max-width: 768px) {
  .MainBody .body .Nodata .text-center {
    margin: 0;
    padding: 25px 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .MainBody .body .Nodata .text-center {
    margin: 0 50px;
    padding: 25px 0;
  }
}

   // background-color: #34c299;
        // width: 100%;
        // font-size: 25px;
        // font-weight: 600;
        // text-transform: uppercase;
        // padding: 25px 50px;
        // margin: 0 250px;