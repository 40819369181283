.Otp {
  height: 100%;

  .otp-img {
    background-image: url('../../assests/images/hero-vendor.jpeg');
    height: 300px;
    min-width: 100%;
    background-position: center;
    background-size: cover;
    object-fit: cover;
  }

  .otp-container {
    max-width: 560px;
    background-color: #fff;
    padding: 50px;
    margin: -88px auto 0px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  }

  .otp-container span {
    display: block;
    width: 100%;
    color: #666666;
  }

  .otp-container input {
    width: 100%;
    width: 100%;
    padding: 10px;
    border: 2px solid #5ec096;
    font-size: 18px;
    outline: none;
    background: none;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 5px;
  }

  .otp-container button {
    background: #5ec096;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 22px;
    padding: 10px 10px;
    margin-top: 15px;
    width: 100%;
    outline: unset;
    border: 0;
    color: #fff;
  }

  .disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  .customermainbox h1 {
    font-weight: bold;
    text-transform: capitalize;
    color: #3c525d;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .customermainbox p {
    color: #666666;
  }
  .otp-flex {
    margin-bottom: 70px;
  }
  .resendOtp{
    cursor: pointer;
  }
}
