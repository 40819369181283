.rewardsListing {
  .listingDiv {
    margin-bottom: 60px;
  }
  .rewardsListing_title {
    padding: 0 10px;
    margin-top: 15px;

    p {
      font-size: 30px;
      color: #252323;
    }
  }

  .reward-benefit-column {
    text-align: start;

    .rewardInfoTitle {
      margin-top: 20px;

      h5 {
        font-size: larger;
        font-weight: bold;
        color: #000;
      }
    }

    .reward-benefit-data {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: larger;
        color: #666666;
      }

      h6 {
        font-size: 14px;
        color: #666666;
      }

      .rewardBtn {
        padding: 5px 15px;
        border-radius: 5px;
        color: #fff;
        font-weight: 500;

        svg {
          position: relative;
          top: -2px;
          left: -5px;
        }
      }

      .pointsTitle {
        .checkoutAway {
          width: auto;
          margin-bottom: 15px;
        }

        h5 {
          color: #4b937c;
          font-weight: 700;
        }
      }
    }
  }

  .pendingClr {
    position: absolute;
    max-width: 100%;
    margin-right: 10px;
    width: -webkit-fill-available;
    opacity: 0.9;
    bottom: 20px;
    display: block;
    background: #000;
    background: rgb(218, 26, 5);
    color: #fff;
    padding: 15px 5px;
    font-size: x-large;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .donatedClr {
    position: absolute;
    max-width: 100%;
    margin-right: 10px;
    width: -webkit-fill-available;
    opacity: 0.9;
    bottom: 20px;
    display: block;
    background: #000;
    background: rgba(250, 163, 36);
    color: #fff;
    padding: 15px 5px;
    font-size: x-large;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .claimedClr {
    position: absolute;
    max-width: 100%;
    width: -webkit-fill-available;
    opacity: 0.9;
    margin-right: 10px;
    bottom: 20px;
    display: block;
    background: #000;
    background: rgba(70, 144, 121);
    color: #fff;
    padding: 15px 5px;
    font-size: x-large;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  .checkoutAway {
    background: #5ec096;
    width: 90px;
  }

  .btnRed {
    background: rgb(218, 26, 5);
    color: #fff;
  }

  .btnGreen {
    background: rgba(70, 144, 121);
    color: #fff;
  }

  .btnYellow {
    background: rgb(250, 163, 36);
    color: #fff;
  }
}

@media (max-width: 768px) {
  .rewardsListing {
    .listingDiv {
      margin-bottom: 110px;
    }
    .reward-benefit-column {
      margin-bottom: 20px;

      .reward-benefit-data {
        flex-direction: column;
        align-items: start;
        margin-top: 15px;

        .pointsTitle {
          margin-top: 15px;

          h5 {
            font-size: 18px;
          }
        }

        .rewardBtnDiv {
          margin-top: 20px;
          width: 100%;
          text-align: center;
        }
      }
    }
    .imageSize {
      h2 {
        font-size: 20px;
        padding: 10px 5px;
      }
    }
    .rewardsListing_title p {
      font-size: 26px;
    }
    .reward-benefit-column {
      .reward-benefit-data h4 {
        font-size: 16px;
        .rewardInfoTitle h5 {
          font-size: 18px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .rewardsListing {
    .rewardList {
      width: 88%;
      padding: 10px 5px;
      font-size: 18px;
    }

    .reward-benefit-column {
      .reward-benefit-data {
        h4 {
          font-size: 18px;
        }

        .rewardBtn {
          font-size: 14px;
        }

        .pointsTitle {
          text-align: center;
          padding: 0 12px;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .rewardsListing {
    .rewardList {
      width: 87%;
      padding: 10px 5px;
      font-size: 18px;
    }
  }
}
