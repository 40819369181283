.Auth {
  background-image: url('../../assests/images/slider-4.jpeg');
  height: 600px;
  min-width: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;

  .headTitle{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pagehead_title{
    width: 100%;
    margin-top: 20px;
    background: #fff;
    padding: 15px 0;
    text-align: center;
    color: #000;
    max-width: 400px;
    font-weight: 800;
    font-size: 32px;
    letter-spacing: 2px;
    border: 2px solid #ebebeb;
  }
}
